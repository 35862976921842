<template>
  <div class="flex flex-row justify-start items-start text-left w-full mb-4">
    <InputText id="search" v-model="note_search" placeholder="Search " @keydown.enter="updateSearch" class="" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px" />
    <Button class="p-button-secondary p-button w-36" label="" icon="pi pi-search" name="search-button" @click="updateSearch" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px" v-if="showSearchButton" />
    <Button class="p-button-secondary p-button w-36" label="" icon="pi pi-times" name="clear-button" @click="clearFilters" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px" v-else />
  </div>

  <Dataview :value="referrals" :paginator="false" :totalRecords="referralsPaginator?.total" :loading="isLoading" dataKey="id" v-model:filters="filters1" filterDisplay="menu" :filters="filters1" responsiveLayout="scroll" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport" :rows="8" :lazy="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} notes" @filter="onFilterChange($event)" @sort="onSort($event)" @page="onPageChange($event)">
    <template #list="slotProps">
      <div v-auto-animate>
        <div class="w-full flex flex-row p-3 justify-center items-center min-h-[50px] cursor-pointer encounter-note-list-item bg-white border-b border-gray-200 last:border-b-0 hover:bg-gray-100" v-for="(encounterNote, idx) in slotProps.items" :key="idx" @click="selectStandardNote(encounterNote.id)">
          <div class="text-gray-400 mr-3">
            <i style="font-size: 1.7rem" class="fas fa-file" v-if="encounterNote.interaction_type_category_id === 1" v-tooltip.right="'Standard Note'"> </i>
            <i style="font-size: 1.7rem" class="fas fa-file-medical" v-else-if="encounterNote.interaction_type_category_id === 2" v-tooltip.right="'Phone Consult Note'"> </i>
            <i style="font-size: 1.6rem" class="fas fa-file-lines" v-else-if="encounterNote.interaction_type_category_id === 3" v-tooltip.right="'SOAP Note'"> </i>
          </div>

          <div class="flex flex-col text-left text-sm font-display text-gray-800">
            <span v-if="encounterNote.referral_form.patient_first_name || encounterNote.referral_form.patient_last_name"> {{ encounterNote.referral_form.patient_first_name }} {{ encounterNote.referral_form.patient_last_name }} </span>
            <span v-else>Note</span>
            <span class="text-xs text-gray-500"> {{ formatDate(encounterNote.updated_at) }} </span>
          </div>
          <span class="px-1 py-1 my-auto ml-auto text-xs font-md rounded text-center text-gray-500">
            {{ encounterNote.status }}
          </span>
          <Button text icon="pi pi-trash" class="p-button-outlined p-button-secondary" @click.stop="deleteEncounterNoteOnClick(encounterNote)" :disabled="encounterNote.status === 'Signed Off' || encounterNote.status === 'Complete'" />
        </div>
      </div>
    </template>
    <template #empty>
      <div class="flex flex-col justify-center items-center py-24 mb-6 rounded-md bg-white">
        <i class="pi pi-file text-gray-500 mb-2" style="font-size: 2rem"></i>
        <h1 class="text-gray-500 text-lg font-medium mb-2">No Notes found.</h1>
        <Button class="p-button p-button-sm" label="Clear Filters" icon="pi pi-times" name="clear-filters-button" @click="clearFilters" v-if="this.referrals.length > 0" />
      </div>
    </template>
    <template #loading>
      <div class="flex flex-col justify-center items-center py-24 mb-6 rounded-md bg-white">
        <i class="pi pi-spin pi-spinner text-gray-500 mb-2" style="font-size: 2rem"></i>
        <h1 class="text-gray-500 text-lg font-medium mb-2">Loading...</h1>
      </div>
    </template>
  </Dataview>

  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" style="max-width: 470px" :draggable="false" group="templating">
    <template #message="slotProps">
      <div class="flex flex-col justify-center items-center py-3 w-full">
        <i :class="slotProps.message.icon" style="font-size: 5rem" class="mb-5 text-red-400"></i>
        <p class="">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import AddOrEditEncounterNoteForm from '@/components/encounter_note/AddOrEditEncounterNoteForm.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { FilterOperator } from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import globals from '../../globals';
import Menu from 'primevue/menu';
import Dataview from 'primevue/dataview';
import Modal from '@/components/misc/Modal.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SidebarNoteList',
  data() {
    return {
      filters1: null,
      statuses: ['Draft', 'Signed Off', 'Complete'],
      types: ['phone', 'econsult'],
      selectedReferral: null,
      noteStatus: {
        Draft: 'Draft',
        'Signed Off': 'Signed Off',
        Complete: 'Complete',
      },
      isLoading: false,
      initialLoading: true,
      mainUrl: globals.APIs.getReferralsPaginationUrl(),
      currentUrl: null,
      currentFilters: [],
      currentSort: null,
      referrals: [],
      referralsPaginator: null,
      formKey: 0,
      note_search: '',
      showClearButton: false,
      showSearchButton: true,
      filterMenuItems: [
        {
          label: 'Filter By',
          icon: 'pi pi-filter',
          items: [
            { label: 'Draft', action: this.filterStatusOnClick },
            { label: 'Signed Off', action: this.filterStatusOnClick },
          ],
        },
      ],
      sortMenuItems: [
        {
          label: 'Sort By',
          icon: 'pi pi-sort-alt',
          items: [
            { label: 'Date Edited', icon: 'pi pi-plus', action: this.sortOnClick },
            { label: 'Date Created', icon: 'pi pi-download', action: this.sortOnClick },
          ],
        },
      ],
      selectedFilterStatus: null,
      selectedSort: {
        type: 'Date Edited',
        desc: false,
      },
      selectedEncounterNoteId: null,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'isOrgUnitHead', 'actingUser', 'referral', 'activeTour']),
  },
  created() {
    this.initFilters1();
    if (this.$route.params.filterStatus) {
      this.currentFilters = [{ name: 'status', value: this.$route.params.filterStatus }];
    }

    let url = this.mainUrl + '?page=1';
    this.currentUrl = url;
    this.currentSort = { sortField: 'updated_at', sortOrder: 'desc' };
    this.currentFilters.push({ name: 'interaction_types', value: ['encounter_note'] });

    if (this.presets) {
      this.presets.forEach((preset) => {
        if (this.filters1[preset.field]) {
          this.filters1[preset.field].value = preset.value;
        }
        this.currentFilters.push({ name: preset.field, value: preset.value });
      });
    }

    this.getReferralsMethod();
  },
  components: {
    DataTable,
    Dropdown,
    AddOrEditEncounterNoteForm,
    Column,
    Button,
    InputText,
    Calendar,
    Menu,
    Modal,
    Dataview,
  },
  emits: ['note-selected', 'create-new-note'],
  methods: {
    ...mapActions(['getReferralsPagination', 'deleteEncounterNote']),
    updateSearch() {
      this.onFilterChange({ filters: { 'referral_form.patient_full_name': { value: this.note_search } } });
      this.showClearButton = true;
      this.showSearchButton = false;
    },
    clearFilters() {
      this.onFilterChange({ filters: {} });
      this.note_search = '';
      this.showClearButton = false;
      this.showSearchButton = true;
    },
    setFilterValue() {
      this.selectedFilterStatus = 'Draft';
      this.onFilterChange({ filters: { status: { value: this.selectedFilterStatus } } });
    },
    rowStyle(data) {
      if (data.id === this.selectedEncounterNoteId) {
        return { background: '#ebebf1' };
      }
    },
    onRowSelect(event) {
      this.selectStandardNote(event.data.id);
    },
    selectStandardNote(id) {
      this.selectedEncounterNoteId = id;
      this.$emit('note-selected', id);
    },
    addNoteOnClick() {
      if (this.isNativePlatform()) {
        this.$router.push('/add-note');
      } else {
        this.selectedEncounterNoteId = null;
      }
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        updated_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        interaction_types: { value: null, matchMode: FilterMatchMode.EQUALS },
        'referral_form.patient_full_name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      };
    },
    getReferralsMethod() {
      this.isLoading = true;
      this.getReferralsPagination({ url: this.currentUrl, filters: this.currentFilters, sort: this.currentSort, user_id: this.actingUser.id, page_size: 10, required_relations: ['referral_form', 'interaction_type', 'specialty', 'referee', 'referral_person'], setReferrals: this.initialLoading })
        .then((data) => {
          this.referralsPaginator = data;
          if (!this.isNativePlatform()) {
            this.referrals = data.data;
          } else if (this.currentUrl.split('?page=')[1] === '1') {
            this.referrals = data.data;
          } else {
            this.referrals = this.referrals.concat(data.data);
          }
          this.isLoading = false;
          this.initialLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showGenericErrorToast();
        });
    },
    onFilterChange(event) {
      this.currentFilters = [];
      if (this.presets) {
        this.presets.forEach((preset) => {
          if (this.filters1[preset.field]) {
            this.filters1[preset.field].value = preset.value;
          }
          this.currentFilters.push({ name: preset.field, value: preset.value });
        });
      }
      this.currentFilters.push({ name: 'interaction_types', value: ['encounter_note'] });
      Object.keys(event.filters).forEach((key) => {
        if ((event.filters[key].value !== undefined && event.filters[key].value !== null) || (event.filters[key].constraints && event.filters[key].constraints[0].value)) {
          if (key === 'referral_form.patient_full_name') {
            this.currentFilters.push({
              //This name is passed back to a custom pagination filter for 'encounter_search'
              name: 'encounter_search',
              value: event.filters[key].value,
              fields: ['referral_form.patient_full_name', 'referral_form.referral_question'],
            });
          } else {
            this.currentFilters.push({ ...event.filters[key], name: key });
          }
        }
      });
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getReferralsMethod();
    },
    onSort(event) {
      this.currentSort = { sortField: event.sortField, sortOrder: event.sortOrder === 1 ? 'asc' : 'desc' };
      let url = this.mainUrl + '?page=1';
      this.currentUrl = url;
      this.getReferralsMethod();
    },
    onPageChange(event) {
      let page = event.page + 1;
      let url = this.mainUrl + '?page=' + page;
      this.currentUrl = url;
      this.getReferralsMethod();
    },
    toggleFilterMenu(event) {
      this.$refs.filterMenu.toggle(event);
    },
    toggleSortMenu(event) {
      this.$refs.sortMenu.toggle(event);
    },
    sortOnClick(sortType) {
      if (sortType === this.selectedSort.type) {
        this.selectedSort.desc = !this.selectedSort.desc;
      } else {
        this.selectedSort.type = sortType;
        this.selectedSort.desc = true;
      }
      this.onSort({ sortField: 'updated_at', sortOrder: this.selectedSort.type === 'Date Edited' ? 1 : -1 });
    },
    filterStatusOnClick(status) {
      if (this.selectedFilterStatus !== status) {
        this.selectedFilterStatus = status;
        this.onFilterChange({ filters: { status: { value: this.selectedFilterStatus } } });
      } else {
        this.selectedFilterStatus = null;
        this.onFilterChange({ filters: {} });
      }
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = scrollTop + windowHeight;

      if (scrollPosition >= documentHeight * 0.75 && !this.isLoading && this.currentUrl.split('?page=')[1] !== this.referralsPaginator?.last_page_url.split('?page=')[1]) {
        const currentPage = parseInt(this.currentUrl.split('?page=')[1]);
        this.currentUrl = this.mainUrl + `?page=${currentPage + 1}`;
        this.getReferralsMethod();
      }
    },
    deleteEncounterNoteOnClick(encounterNote) {
      if (encounterNote.status !== 'Draft') {
        return;
      }
      encounterNote.user_id = this.loggedInUser.id;
      encounterNote.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      encounterNote.submit_for_billing = false;
      encounterNote.is_signed_off = false;
      encounterNote.status = 'Draft';

      if (this.selectedEncounterNoteId === encounterNote.id) {
        this.selectedEncounterNoteId = null;
        this.formKey++;
      }

      this.$confirm.require({
        group: 'templating',
        message: 'Are you sure you want to delete this draft note?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-text p-button-secondary',
        acceptLabel: 'Delete Note',
        rejectLabel: 'Cancel',
        accept: () => {
          this.deleteEncounterNote(encounterNote)
            .then(() => {
              this.idx++;
              this.$toast.add({
                severity: 'success',
                summary: 'Note Deleted',
                detail: 'You successfully deleted this note.',
                life: 3000,
              });
              this.getReferralsMethod();
            })
            .catch(() => {
              this.showGenericErrorToast();
            });
        },
      });
    },
    handleEncounterNoteSaved(id) {
      this.selectedEncounterNoteId = id;
      this.getReferralsMethod();
    },
  },
  mounted() {
    if (this.isNativePlatform()) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  unmounted() {
    if (this.isNativePlatform()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
};
</script>

<style lang="scss">
.p-dataview .p-dataview-content {
  background-color: transparent;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

.referral-list-table.p-datatable .p-column-filter {
  display: block;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.status-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-InReview {
    background: #946da6;
    color: #6b039c;
  }

  &.status-SignedOff {
    background: #946da6;
    color: #6b039c;
  }

  &.status-Complete {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Accepted {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Faxed {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Cancelled {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-Upcoming {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-Pending {
    background: #f8da87;
    color: #81620c;
  }

  &.status-Draft {
    background: #f8da87;
    color: #81620c;
  }
}

.Draft {
  background: #8d8d8d !important;
  border-color: #ffab66;
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: 0.4rem;
            min-width: 30%;
            display: inline-block;
            margin: -0.4rem 1rem -0.4rem -0.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: 0.5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #c63737;
}

.p-button.p-button-info {
  background: var(--vh-blue) !important;
}

.cal-leg {
  width: 15px;
  height: 15px;
  background-color: var(--vh-blue);
  margin-right: 10px;
  border-radius: 50px;
}

.p-editable-column > .p-inputtext {
  font-size: 0.7rem;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 1rem;
}
.truncate {
  max-width: 320px;
}

.truncate > span:nth-child(2):not(.ui-icon) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
