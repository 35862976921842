<template>
  <Sidebar ref="notesSidebar" :visible="isVisible" @update:visible="updateVisibility" :baseZIndex="1000" position="right" id="notes-sidebar" @hide="$emit('hide')">
    <template #header>
      <div class="flex flex-row items-center text-gray-500 w-full cursor-pointer hover:text-gray-800" v-if="showNoteList" @click="handleNoteSelected(null)">
        <!-- <i class="fas fa-file-circle-plus" style="font-size: 1.5rem"></i> -->
        <!-- <i class="pi pi-file-plus" style="font-size: 0.8rem; width: 1.1rem"></i> -->
        <i class="pi pi-file-plus"></i>
        <h2 class="ml-2 text-lg text-semibold">Add New Note</h2>
      </div>
      <div class="flex flex-row items-center text-gray-500 w-full cursor-pointer hover:text-gray-800" @click="showNoteList = true" v-if="!showNoteList">
        <i class="pi pi-chevron-left" style="font-size: 0.8rem; width: 1.1rem"></i>
        <h2 class="ml-2 text-lg text-semibold">Back</h2>
      </div>
    </template>

    <div class="notes-container">
      <Transition :name="showNoteList ? 'fade-left' : 'fade-right'" mode="out-in">
        <div v-if="showNoteList" key="list" class="notes-view">
          <SidebarNoteList @create-new-note="showNoteList = false" @note-selected="handleNoteSelected" />
        </div>
        <div v-else-if="selectedNoteId != null" key="existing-note" class="notes-view">
          <AddOrEditEncounterNoteForm :selectedNoteId="selectedNoteId" @encounter-note-saved="handleNoteSaved" :removePadding="true" />
        </div>
        <div v-else key="editor" class="notes-view">
          <AddOrEditStandardNoteForm :selectedNoteId="selectedNoteId" @encounter-note-saved="handleNoteSaved" />
        </div>
      </Transition>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from 'primevue/sidebar';
import AddOrEditStandardNoteForm from '../encounter_note/AddOrEditStandardNoteForm.vue';
import SidebarNoteList from '../encounter_note/SidebarNoteList.vue';
import AddOrEditEncounterNoteForm from '../encounter_note/AddOrEditEncounterNoteForm.vue';

export default {
  name: 'NoteSidebar',
  components: {
    Sidebar,
    AddOrEditStandardNoteForm,
    SidebarNoteList,
  },
  data() {
    return {
      showNoteList: true, // Start with the note list view
      selectedNoteId: null,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.modelValue;
    },
  },
  methods: {
    updateVisibility(value) {
      this.$emit('update:modelValue', value);
    },
    handleNoteSelected(id) {
      this.selectedNoteId = id;
      this.showNoteList = false;
    },
    handleNoteSaved(id) {
      this.selectedNoteId = id;
      // this.$toast.add({
      //   severity: 'success',
      //   summary: 'Success',
      //   detail: 'Note saved successfully',
      //   life: 3000,
      // });
    },
  },
  emits: ['update:modelValue', 'hide'],
};
</script>

<style scoped>
.notes-container {
  position: relative;
  height: calc(100vh - 120px);
  overflow-y: none;
}

.notes-view {
  width: 100%;
  height: 100%;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateX(0);
} */

.fade-right-leave-active,
.fade-right-enter-active,
.fade-left-leave-active,
.fade-left-enter-active {
  transition: all 0.35s ease;
}

.fade-right-enter-from {
  opacity: 0;
  translate: 10px 0;
}

.fade-right-enter-to {
  opacity: 1;
  translate: 0 0;
}

.fade-right-leave-from {
  opacity: 1;
  translate: 0 0;
}

.fade-right-leave-to {
  opacity: 0;
  translate: -10px 0;
}

.fade-left-enter-from {
  opacity: 0;
  translate: -10px 0;
}

.fade-left-enter-to {
  opacity: 1;
  translate: 0 0;
}

.fade-left-leave-from {
  opacity: 1;
  translate: 0 0;
}

.fade-left-leave-to {
  opacity: 0;
  translate: 10px 0;
}
</style>
