<template>
  <div class="flex flex-col w-full" :id="id">
    <div class="flex flex-row justify-between items-center p-2 bg-gray-100 border">
      <label class="text-sm font-bold font-display">
        {{ title }}
      </label>
      <i class="cursor-pointer pi pi-times" @click="$emit('closedComponent')"></i>
    </div>
    <div class="flex flex-col p-2 w-full border">
      <Textarea :id="id + 'TextArea'" v-model="text" :autoResize="false" rows="5" cols="30" required maxLength="65535" aria-label="textWithFileUploadInputComponentText" placeholder="Type here..." @keydown.tab.prevent="processTabs($event)" v-tooltip.focus.top="{ value: 'Please enter a description for this addendum.', disabled: !inputTextHasError }" />
      <FileUpload name="TextWithFileUploadInputComponentFiles[]" :multiple="true" accept="image/*, application/pdf, .doc, .docx" :maxFileSize="20000000" :file-limit="maxFileLimit" @select="appendFileToRequest($event)" aria-label="textWithFileUploadInputComponentFileUpload">
        <template #header="{ chooseCallback }">
          <div class="flex flex-row justify-end pt-2 mt-2 w-full border-t">
            <!-- <div class="flex flex-row items-center px-2 py-1 mr-2 rounded border border-gray-400 cursor-pointer hover:bg-gray-100" @click="chooseCallback()" :id="id + 'AttachBtn'">
              <i class="mr-1 text-gray-700 pi pi-paperclip" style="font-size: 1rem" id="showRegenerateSummaryBtn"></i>
              <span class="text-sm">Attach</span>
            </div> -->
            <!-- <div class="flex flex-row items-center px-2 py-1 text-white rounded cursor-pointer hover:bg-gray-600 bg-secondary" @click="submit()" :id="id + 'SendBtn'">
              <i class="mr-1 pi pi-send" style="font-size: 1rem" id="showRegenerateSummaryBtn"></i>
              <span class="text-sm">Submit</span>
            </div> -->
            <Button icon="pi pi-paperclip" class="p-button-secondary p-button-sm p-button-outlined p-button-sm mr-2" label="Attach" @click="chooseCallback()" :id="id + 'AttachBtn'" />
            <Button icon="pi pi-send" class="p-button-secondary p-button-sm" label="Submit" @click="submit()" :id="id + 'SendBtn'" />
          </div>
        </template>
        <template #content="{ files, removeFileCallback }">
          <Message severity="info" v-if="maxFileLimit == files.length">You can only upload {{ maxFileLimit }} files at the same time. If you need to upload more files, upload the current files and start another upload request.</Message>
          <template v-if="files.length > 0">
            <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="flex flex-col p-3 rounded surface-border">
              <div class="flex justify-between pb-2 mb-2 border-b">
                <div class="flex">
                  <div class="place-self-center mr-2">
                    <img v-if="file.objectURL" role="presentation" :alt="file.name" :src="file.objectURL" width="100" height="50" class="shadow-2" />
                    <i class="place-self-center pi pi-file" style="font-size: 2rem" v-else></i>
                  </div>
                  <div class="flex flex-col">
                    <div class="text-sm">{{ file.name }}</div>
                    <div>{{ formatSize(file.size) }}</div>
                  </div>
                </div>
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" @click="removeFileCallback(index)" />
              </div>
            </div>
          </template>
        </template>
      </FileUpload>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';

export default {
  name: 'TextWithFileUploadInputComponent',
  props: ['title', 'id'],
  emits: ['submittedForm', 'closedComponent'],
  data() {
    return {
      text: '',
      files: [],
      filesToUpload: new FormData(),
      inputTextHasError: false,
    };
  },
  components: {
    Button,
    Textarea,
  },
  computed: {
    ...mapGetters(['referralForm', 'referralFormFields', 'loggedInUser', 'referralAddendums']),
  },
  methods: {
    ...mapActions(['getReferralForm', 'getReferralFormFields', 'downloadFilesOfReferralFormField', 'viewFileOfReferralFormField', 'createReferralAddendum', 'signOffReferralForm', 'viewDownloadedFile']),
    submit() {
      if (!this.text) {
        this.inputTextHasError = true;
        document.getElementById(this.id + 'TextArea').focus();
      } else {
        this.filesToUpload.append('text', this.text);
        this.$emit('submittedForm', this.filesToUpload);
      }
    },
    appendFileToRequest(event) {
      this.filesToUpload = new FormData();
      for (let i = 0; i < event.files.length; i++) {
        this.filesToUpload.append('attached_files[]', event.files[i]);
      }
    },
    removeFileFromRequest(event, file, index) {
      this.fileProperties.splice(index, 1);
      // Removing item from form data implemented from: https://stackoverflow.com/a/41691785/10052594
      var values = this.filesToUpload.getAll('attached_files[]');
      var index = values.indexOf(file);
      values.splice(index, 1);
      // Delete existing file entries since they will be reset
      this.filesToUpload.delete('attached_files[]');
      values.forEach((file) => {
        this.filesToUpload.append('attached_files[]', file);
      });
      event(index);
    },
    formatSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    processTabs(event) {
      this.text = this.text + '\t';
    },
    pasteNewAddendum(addendum) {
      this.text = addendum;
    },
  },
  created() {},
};
</script>

<style>
[aria-label='textWithFileUploadInputComponentFileUpload'] .p-fileupload-buttonbar {
  padding: 0rem !important;
  border: 0px !important;
  background-color: white !important;
}
[aria-label='textWithFileUploadInputComponentFileUpload'] .p-fileupload-content {
  padding: 0rem !important;
  border: 0px !important;
}
[aria-label='textWithFileUploadInputComponentText'] {
  border: 0px;
  resize: none;
}
</style>
