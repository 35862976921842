import { createApp } from 'vue';
import 'v-calendar/style.css';
import 'vue-loading-overlay/dist/vue-loading.css';

import App from './App.vue';
import { defineAsyncComponent } from 'vue';
import store from './store';
import router from './router';
import { globalMixin } from './mixins';
import sentryConfig from './sentryConfig';
import { createHead } from '@unhead/vue';
import PrimeVue from 'primevue/config';
import axios from 'axios';
import IdleVue from 'idle-vue';
import 'primevue/resources/themes/lara-light-green/theme.css';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import * as Sentry from '@sentry/vue';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';

import Vue3Tour from 'vue3-tour';
import 'vue3-tour/dist/vue3-tour.css';

// TODO: Async load vue-vimeo-player
// const vueVimeoPlayer = defineAsyncComponent(() => import('vue-vimeo-player'));
import vueVimeoPlayer from 'vue-vimeo-player';
import VueMixpanel from 'vue-mixpanel';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons/faUserDoctor';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons/faSquareCheck';
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons/faNotesMedical';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart';
import { faHandHolding } from '@fortawesome/free-solid-svg-icons/faHandHolding';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons/faFingerprint';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faAward } from '@fortawesome/free-solid-svg-icons/faAward';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons/faStethoscope';
import { faHourglass } from '@fortawesome/free-solid-svg-icons/faHourglass';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faScroll } from '@fortawesome/free-solid-svg-icons/faScroll';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons/faPeopleGroup';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal';
import { faSuitcaseMedical } from '@fortawesome/free-solid-svg-icons/faSuitcaseMedical';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faFileMedical } from '@fortawesome/free-solid-svg-icons/faFileMedical';
import { faFileWaveform } from '@fortawesome/free-solid-svg-icons/faFileWaveform';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines';
import { faFilePrescription } from '@fortawesome/free-solid-svg-icons/faFilePrescription';
import { faHeart as farFaHeart } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faUserDoctor, faBolt, faCoffee, faCircleCheck, faSquareCheck, faNotesMedical, faPencil, faCertificate, faPaperPlane, faLink, faPlus, faClock, faHandHoldingHeart, faHandHolding, faHeart, farFaHeart, faFingerprint, faCirclePlay, faAward, faStethoscope, faHourglass, faBook, faScroll, faFileInvoice, faPeopleGroup, faBookOpen, faMedal, faSuitcaseMedical, faUpload, faFileMedical, faFileWaveform, faFile, faFileLines, faFilePrescription, faFileCirclePlus);

const app = createApp(App);
const head = createHead();

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

app.component('fa', FontAwesomeIcon);
app.component('Toast', Toast);
app.component('ConfirmDialog', ConfirmDialog);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.mixin(globalMixin);
app.use(head);
app.use(store);
app.use(router);
app.use(ToastService);
app.use(Vue3Tour);
app.use(vueVimeoPlayer);
app.use(ConfirmationService);
app.use(autoAnimatePlugin);
app.use(PrimeVue, {
  // csp: {
  //   nonce: '...',
  // },
  ripple: true,
});
app.use(IdleVue, {
  store,
  idleTime: 60000 * 9, //Set the minutes here 6 minutes less than the desired timeout duration as there's a 5 minute warning modal before logout.
  events: ['keydown', 'mousedown', 'touchstart', 'load'],
  startAtIdle: false, // This is important, else the logout warning modal will pop-up on page refresh.
});
app.use(VueMixpanel, {
  token: import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN ? import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN : 'temp',
  config: {
    debug: import.meta.env.VITE_APP_ENV !== 'production',
  },
});

app.config.errorHandler = (err) => {
  Sentry.captureException(err);
  throw err;
};

if (import.meta.env.VITE_APP_ENV !== 'local') {
  Sentry.init({ sentryConfig });
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      let currentPath = window.location.pathname;
      if (!['/registration', '/logout', '/login', '/switchtooauth'].includes(currentPath)) {
        router.replace({ name: 'Logout', query: { autoLogout: 'true', redirect: currentPath } });
      }
    }
    return Promise.reject(error);
  }
);

app.mount('#app');
