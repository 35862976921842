<template>
  <div class="flex flex-col items-center" id="encounterNoteContainer" :class="{ 'p-4 sm:p-6 sm:pt-4 pb-4 mx-auto mt-4 w-full rounded-2xl tile animated shadow-md': !removePadding }">
    <div class="flex flex-row justify-between items-center w-full text-left">
      <div class="flex flex-row w-full">
        <div class="w-full text-center text-gray-300">
          <div class="flex flex-row">
            <fa :icon="['fas', note_type_icon]" id="note_type_icon" class="mr-4 mt-2 text-gray-400" style="font-size: 2rem" />
            <flex class="flex-row">
              <div class="flex flex-row items-center">
                <h2 id="encounterNoteTitle" class="text-lg font-semibold text-left text-gray-600">
                  {{ note_type_options.find((option) => option.id === note_type)?.display_name || 'Standard Note' }}
                  <!-- <span class="text-blue-500">{{ encounterNote.id ? `#${encounterNote.id}` : '' }}</span> -->
                </h2>
              </div>
              <div class="flex flex-row w-full items-center text-left">
                <h5 v-if="encounterNote.id" class="text-xs text-gray-500">Saved {{ encounterNote.updated_at ? formatDateTime(encounterNote.updated_at) : formatDateTime(new Date()) }}</h5>
                <i v-if="encounterNote.id" class="pi pi-save ml-2 text-gray-400" style="font-size: 0.75rem"></i>
                <h5 v-else class="text-xs text-gray-500">Unsaved Draft</h5>
              </div>
            </flex>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end pl-4">
        <div v-if="encounterNote.id != null" class="flex justify-center items-center w-8 h-8 rounded-lg bg-secondary sm:hidden" @click="toggleMenu($event)" id="encounterNoteMenuButton">
          <i class="text-white pi pi-ellipsis-h" style="font-size: 1rem"></i>
        </div>
        <!-- <SplitButton label="Options" class="p-button-sm p-button-secondary hidden sm:flex" icon="pi pi-download" @click="toggleMenu($event)" :model="menuItems"></SplitButton> -->
        <div id="encounterNoteActions" class="sm:flex flex-row hidden">
          <span v-tooltip.top="'Make Call'"><Button icon="pi pi-phone" @click="startCall()" class="p-button-outlined p-button-secondary" text /></span>
          <!-- <span v-tooltip.top="'New Note'"><Button icon="pi pi-plus" @click="$router.push('/add-note')" class="p-button-outlined p-button-secondary" text /></span> -->
          <span v-tooltip.top="'Toggle Editing Mode'"><Button :icon="editingMode ? 'pi pi-lock-open' : 'pi pi-lock'" @click="toggleEditingMode()" :disabled="encounterNote.id == null || encounterNote.status != 'Signed Off'" class="p-button-outlined p-button-secondary" text /></span>
          <span v-tooltip.top="'Make Addendum'"><Button icon="pi pi-pen-to-square" @click="$refs.addendumsComponent.showMakeAddendumInputComponent()" :disabled="encounterNote.id == null || encounterNote.status != 'Signed Off' || encounterNote.status != 'Complete'" class="p-button-outlined p-button-secondary" text /></span>
          <span v-tooltip.top="'Download Note'"><Button icon="pi pi-download" @click="downloadPdfOfCombinedForm(encounterNote.id)" :disabled="encounterNote.id == null" class="p-button-outlined p-button-secondary" text /></span>
          <span v-tooltip.top="'Fax Note'"><Button icon="pi pi-print" @click="openFaxDialogOnClick()" :disabled="encounterNote.id == null" class="p-button-outlined p-button-secondary" text /></span>
        </div>
      </div>
    </div>
    <div class="seperator-light"></div>
    <div class="w-full">
      <div v-if="!editingMode && encounterNote.status != 'Complete'" class="w-full" @click="toggleEditingMode()">
        <Message severity="info" icon="pi pi-lock" :closable="false" style="margin-bottom: 0px">Click to enable editing mode and edit this note</Message>
      </div>
      <div v-if="encounterNote.status == 'Complete'" class="w-full">
        <Message severity="info" :closable="false" style="margin-bottom: 0px">This note has been submitted for billing and is no longer editable</Message>
      </div>
      <div v-else class="mt-4 w-full">
        <Dropdown v-model="note_type" :options="note_type_options" placeholder="Standard Note" id="note_type_select" class="w-full text-left p-inputtext-sm py-1" :disabled="isComplete" optionLabel="display_name" optionValue="id" @change="onNoteTypeChange" />
      </div>
    </div>
    <form v-auto-animate method="post" autocomplete="off" @submit.prevent="signOffOnClick" @keydown.enter.prevent class="grid grid-cols-2 gap-4 w-full mt-4" ref="form">
      <div class="grid grid-cols-3 col-span-2 gap-4 w-full">
        <div class="col-span-1">
          <DatePicker v-model="encounterNote.call_date" required :popover="{ visibility: 'focus' }" class="relative pb-2">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">Encounter Date </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.call_date" class="relative auth-input" required name="dateTimeInput" maxLength="55" id="dateTimeInput" :disabled="!editingMode" />
            </template>
          </DatePicker>
          <div class="error">{{ errors.call_date }}</div>
        </div>
        <div class="relative col-span-1">
          <DatePicker v-model="encounterNote.start_datetime" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">Start Time </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.start_datetime" class="relative auth-input" required maxLength="55" id="callStartTime" :disabled="!editingMode" />
            </template>
          </DatePicker>
          <div class="error">{{ errors.start_datetime }}</div>
        </div>
        <div class="relative col-span-1">
          <DatePicker v-model="encounterNote.end_datetime" :popover="{ visibility: 'focus' }" class="relative" mode="time" is-required :input-debounce="100">
            <template v-slot="{ inputValue, inputEvents }">
              <label class="auth-input">End Time </label>
              <InputText type="text" :value="inputValue" v-on="inputEvents" v-model="encounterNote.end_datetime" class="relative auth-input" required maxLength="55" id="callEndTime" :disabled="!editingMode" />
            </template>
          </DatePicker>
          <div class="error">{{ errors.end_datetime }}</div>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-3 col-span-2 gap-4 w-full">
        <div class="col-span-1">
          <label :class="['auth-input', { error: errors.patient_first_name }]" for="patient_first_name">Patient First Name </label>
          <InputText type="text" v-model="encounterNote.patient_first_name" class="auth-input" maxLength="100" id="patient_first_name" :disabled="!editingMode" />
          <div class="error">{{ errors.patient_first_name }}</div>
        </div>
        <div class="col-span-1">
          <label :class="['auth-input', { error: errors.patient_last_name }]" for="patient_last_name"> Patient Last Name </label>
          <InputText type="text" v-model="encounterNote.patient_last_name" class="auth-input" maxLength="100" id="patient_last_name" :disabled="!editingMode" />
          <div class="error">{{ errors.patient_last_name }}</div>
        </div>
        <div class="col-span-1">
          <label :class="['auth-input', { error: errors.patient_dob }]" for="patient_dob">Patient Date of Birth </label>
          <InputText type="text" class="auth-input" maxLength="100" id="patient_dob" :disabled="!editingMode" v-if="!editingMode" :value="formatDate(encounterNote.patient_dob)" />
          <VHCDatePicker :inputModel="encounterNote.patient_dob" inputId="patient_dob" :maxInputDate="new Date()" @dateSelected="onDateSelected" :displayAge="true" v-else :key="encounterNote.id" />
          <div class="error">{{ errors.patient_dob }}</div>
        </div>
      </div>
      <template v-if="displayAllFields">
        <div class="grid grid-cols-1 sm:grid-cols-3 col-span-2 gap-4 w-full">
          <div class="col-span-1">
            <label class="auth-input" for="health_card_province" :class="[{ error: errors.health_card_province }]"> Health Card/Policy Type </label>
            <InputText type="text" class="auth-input" maxLength="100" id="health_card_province" :disabled="!editingMode" v-if="isComplete" :value="encounterNote.health_card_province" />
            <Dropdown :class="['w-full text-left']" v-model="encounterNote.health_card_province" :disabled="!editingMode" :options="healthCardProvinceOptions" optionValue="name" optionLabel="name" id="health_card_province" class="w-full text-xs font-normal text-gray-400 auth-input" v-else />
            <div class="error">{{ errors.health_card_province }}</div>
          </div>
          <div class="col-span-1">
            <label :class="['auth-input', { error: errors.health_card_number }]" for="health_card_number"> Health Card/ID Number </label>
            <InputText type="text" v-model="encounterNote.health_card_number" class="auth-input" maxLength="255" id="health_card_number" :disabled="!editingMode" />
            <div class="error">{{ errors.health_card_number }}</div>
          </div>
          <template v-if="encounterNote.health_card_province === 'Blue Cross'">
            <div class="col-span-1">
              <label :class="[{ error: errors.group_number }]" class="auth-input" for="group_number"> Policy/Group Number </label>
              <InputText type="text" v-model="encounterNote.group_number" class="auth-input" maxLength="255" id="group_number" :disabled="!editingMode" />
              <div class="error">{{ errors.group_number }}</div>
            </div>
          </template>
          <div class="col-span-1 select-button-container">
            <label class="auth-input" :class="[{ error: errors.patient_sex }]" for="patient_sex">Patient Sex </label>
            <SelectButton v-model="encounterNote.patient_sex" :options="['Male', 'Female']" id="patient_sex" class="w-full auth-input" style="height: 50px" :disabled="!editingMode" />
            <div class="error">{{ errors.patient_sex }}</div>
          </div>
        </div>
        <div class="grid grid-cols-3 col-start-1 col-end-3 gap-4">
          <div class="flex flex-col col-start-1 col-end-4" v-if="encounterNote && !isComplete">
            <label class="auth-input" for="diagnostic_code_unknown">Diagnostic Code Undetermined/Unknown:</label>
            <InputSwitch class="mt-1 auth-input" id="diagnostic_code_unknown" v-model="diagnosticCodeUnknown" @click="toggleDiagnosticCodeDiagnosis" v-tooltip="'Try to search for the diagnostic code for the patient below. If you are unable to find it or it is unknown click this toggle and enter a diagnosis below.'" :disabled="!editingMode" />
          </div>

          <div class="col-start-1 col-end-4" v-if="encounterNote && !hasProvinceOfPractice(actingUser, ['ON']) && !diagnosticCodeUnknown">
            <label class="auth-input" :class="[{ error: errors.diagnostic_code }]" for="diagnostic_code">Diagnostic Code</label>
            <InputText type="text" class="auth-input" maxLength="100" id="diagnostic_code" :disabled="!editingMode" v-if="isComplete" v-model="encounterNote.diagnostic_code.code_text" />
            <AutoComplete class="w-full auth-input" v-model="encounterNote.diagnostic_code" :suggestions="diagnosticCodes" field="code_text" @complete="searchDiagnosticCode($event)" inputId="selenium_diagnostic_code" id="diagnostic_code" placeholder="Start Typing And Select Option" :completeOnFocus="true" forceSelection v-else />
            <div class="error">{{ errors.diagnostic_code }}</div>
          </div>
          <div class="col-start-1 col-end-4" v-else-if="encounterNote && hasProvinceOfPractice(actingUser, ['ON'])">
            <label class="auth-input" for="diagnostic_code" :class="[{ error: errors.diagnostic_code }]">OHIP Diagnostic Code<i class="ml-2 pi pi-question-circle" v-tooltip.bottom="'Diagnostic codes are used to group and identify diseases, disorders, symptoms and other reasons for patient encounters. Use our diagnostic code lookup by typing the code, or spell out the diagnosis and a list of related codes will popup for you.'"></i></label>
            <InputText type="text" class="auth-input" maxLength="100" id="diagnostic_code" :disabled="!editingMode" v-if="isComplete" v-model="encounterNote.diagnostic_code.code_text" />
            <AutoComplete class="w-full auth-input" v-model="encounterNote.diagnostic_code" :suggestions="filteredDiagnosticCodesOntario" field="code_text" @complete="searchDiagnosticCodeOntario($event)" inputId="diagnostic_code_ontario" placeholder="Start Typing And Select an Option" :completeOnFocus="true" v-else />
            <div class="error">{{ errors.diagnostic_code }}</div>
          </div>

          <div class="col-start-1 col-end-4" v-if="hasProvinceOfPractice(actingUser, 'ON') || diagnosticCodeUnknown">
            <label class="auth-input" for="diagnosis" :class="[{ error: errors.diagnosis }]"> Diagnosis </label>
            <InputText v-model="encounterNote.diagnosis" class="auth-input" id="diagnosis" name="diagnosis" required placeholder="Please specify your diagnosis of the patient" :disabled="isComplete" />
            <div class="error">{{ errors.diagnosis }}</div>
          </div>
        </div>

        <div class="col-span-2 sm:col-span-1">
          <label class="auth-input" for="consult_with_first_name" :class="[{ error: errors.consult_with_first_name }]">Encounter With First Name</label>
          <InputText type="text" v-model="encounterNote.consult_with_first_name" class="auth-input" maxLength="100" id="consult_with_first_name" :disabled="!editingMode" />
          <div class="error">{{ errors.consult_with_first_name }}</div>
        </div>
        <div class="col-span-2 sm:col-span-1">
          <label class="auth-input" for="consult_with_last_name" :class="[{ error: errors.consult_with_last_name }]">Encounter With Last Name </label>
          <InputText type="text" v-model="encounterNote.consult_with_last_name" class="auth-input" maxLength="100" id="consult_with_last_name" :disabled="!editingMode" />
          <div class="error">{{ errors.consult_with_last_name }}</div>
        </div>
      </template>
      <!-- <div class="col-start-1 col-end-3 ">
          <ScribeSummaries :referral="encounterNote" @appendToReferral="appendToReferral" v-if="encounterNote.id" />
        </div> -->
      <div class="col-start-1 col-end-3">
        <div class="flex flex-row justify-between w-full">
          <label class="auth-input" for="referral_question"> Details <i class="pi pi-lock ml-1" v-if="encounterNote.status !== 'Draft'" style="font-size: 0.65rem; color: #5e76ff" v-tooltip.top="'This note has been signed off and cannot be edited.'"></i> </label><span v-if="encounterNote.status !== 'Draft'" @click="$refs.addendumsComponent.showMakeAddendumInputComponent()" class="cursor-pointer hover:text-blue-600 ml-2 text-xs text-blue-500 font-semibold font-display">Make addendum?</span>
        </div>
        <Textarea v-model="encounterNote.referral_question" class="p-inputtext p-inputtext-sm" :autoResize="true" :rows="encounterNote.status === 'Draft' ? 5 : 3" cols="30" required maxLength="65535" id="encounter_note_input" @keydown.tab.prevent="processTabs($event)" :disabled="encounterNote.status !== 'Draft'"></Textarea>
      </div>

      <div class="col-start-1 col-end-3" v-if="encounterNote.status !== 'Draft'">
        <div class="flex flex-row justify-between w-full">
          <label class="auth-input" for="billing_code_options">Billing Code</label>
        </div>
        <Dropdown v-model="encounterNote.billing_code" :options="billing_code_options" id="billing_code_select" class="w-full text-left p-inputtext-sm py-1" :disabled="isComplete" optionLabel="option" optionValue="value" @change="onBillingCodeChange" />
      </div>

      <template v-if="!isComplete">
        <div class="flex flex-row col-span-2 gap-x-4 w-full" v-tooltip.top="{ value: 'Saving, Please Wait', disabled: !isLoadingSave && !isLoadingSubmit }">
          <Button label="Edit Note" :icon="''" iconPos="right" type="submit" name="editNoteButton" class="flex col-start-2 col-end-3 justify-center mb-2 w-full p-button-sm" @click="toggleEditingMode()" v-if="encounterNote.status == 'Signed Off' && editingMode == false"> </Button>
          <Button label="Sign Off" :icon="isSaving ? 'pi pi-spin pi-spinner' : ''" iconPos="right" type="submit" name="signOffButton" class="flex col-start-2 col-end-3 justify-center mb-2 w-full p-button-sm" @click="signOffOnClick" :disabled="encounterNote.status == 'Complete'" v-else> </Button>
          <template v-if="['Signed Off'].includes(encounterNote.status) && isSpecialist(loggedInUser) && hasProvinceOfPractice(loggedInUser, ['NS']) && !hasShadowBilled(referral)">
            <Button label="Submit For Billing" iconPos="right" name="submitForBillingButton" class="flex col-start-2 col-end-3 justify-center mb-2 w-full p-button-sm p-button-secondary" @click="submitForBillingOnClick"> </Button>
          </template>
          <template v-else>
            <Button label="Submit For Billing" iconPos="right" name="submitForBillingButton" class="flex col-start-2 col-end-3 justify-center mb-2 w-full p-button-sm p-button-secondary" @click="handleSubmitForBillingOnClick" v-tooltip="{ value: 'Please fill out the full note in order to bill for this consult.', disabled: isReadyForBilling }"> </Button>
          </template>
        </div>
      </template>
    </form>
    <div class="flex flex-row w-full" :class="isComplete ? 'mt-4' : ''">
      <AddendumsComponent :type="'encounter_note'" :referral="encounterNote" :addendums="encounterNoteAddendums" :responseForm="encounterNote.referral_response_form" :hideMakeAddendumButton="true" ref="addendumsComponent" />
    </div>
  </div>
  <div v-if="encounterNote.id" class="mt-4 text-xs text-gray-500 w-full sm:hidden flex flex-row justify-end px-6">
    <i class="pi pi-save mr-2 text-primary" />
    Saved {{ encounterNote.updated_at ? formatDateTime(encounterNote.updated_at) : formatDateTime(new Date()) }} {{ getUserTimeZoneAbbr() }}
  </div>
  <Dialog v-model:visible="openBillingConditionsDialog" modal header="Billing Conditions" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 680px; width: 100%" :dismissable-mask="true">
    <ul class="text-left w-full text-xl mt-1">
      <!-- <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': !hasShadowBilled(referral) }"></i> <span>has shadow billed</span></li> -->
      <!-- <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': !isReadyForBilling }"></i> <span>all required fields filled out</span></li> -->
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': !isSpecialist(loggedInUser) }"></i> <span>user is specialist</span></li>
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': !hasProvinceOfPractice(loggedInUser, ['NS']) }"></i> <span>user is in nova scotia</span></li>
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': actingUser.provider_type !== 'FFS' }"></i> <span>user is FFS</span></li>
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': !encounterNote.is_signed_off }"></i> <span>note signed off.</span></li>
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': note_type !== 2 }"></i> <span>note is Phone Consult Note </span></li>
      <li><i class="pi pi-check-circle text-green-500 mr-2" :class="{ 'text-red-500': encounterNote.billing_code != '0309' }"></i> <span>billing code selected</span></li>
    </ul>
  </Dialog>
  <Dialog v-model:visible="openBillingArrangementDialog" modal :show-header="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" style="max-width: 680px; width: 100%" :dismissable-mask="true">
    <MSI @closeBillingArrangementModal="closeBillingArrangementDialog" />
  </Dialog>
  <TieredMenu ref="menu" :model="menuItems" :popup="true" />
  <div v-if="showFaxConsultDialog">
    <FaxConsultDialog :show="showFaxConsultDialog" @closeFaxConsultDialog="showFaxConsultDialog = false" :referral="referral" :fax_document="fax_document" :documentId="faxDocumentId" :displayEditFaxOption="false" />
  </div>
  <!-- <Loading z-index="99" v-model:active="isLoading" color="#5e76ff" :can-cancel="false" :is-full-page="false" /> -->

  <Dialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :visible="showSubmitForBillingDialog" :modal="true" :dismissableMask="true" :draggable="false" :closable="false" id="confirmSubmitNoteForBilling" style="max-width: 680px; width: 100%">
    <template #header>
      <div class="flex flex-row items-center">
        <h1 class="text-xl font-bold text-gray-700">Submit For Billing</h1>
      </div>
    </template>
    <div class="flex flex-col w-full max-w-lg">
      <div class="px-4 pt-2 w-full max-w-lg text-sm text-left text-gray-600">
        <span class="font-semibold"> How It Works: </span>
        <ul class="px-4 list-disc">
          <li class="pt-1"><span class="font-semibold">Note Requirements: </span> Your notes must be based on a phone call with another healthcare professional.</li>
          <li class="pt-1"><span class="font-semibold">Same Rate:</span> You will receive the same phone call rate for these notes as you do for your other calls.</li>
          <li class="pt-1"><span class="font-semibold">Monthly Payment:</span> Payments for your notes will be processed and issued on the first week of each month, just like our other billing services.</li>
        </ul>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row justify-end w-full">
        <div class="mr-2 w-full">
          <Button label="Cancel" icon="pi pi-ban" class="p-button-outlined p-button-danger justify-start !w-full" @click="showSubmitForBillingDialog = false"></Button>
        </div>
        <div class="-mr-1 w-full">
          <Button @click="submitForBillingOnClick" :disabled="isLoadingSubmit" label="Submit" icon="pi pi-check-circle" id="confirmSubmitNoteForBillingButton" class="!w-full"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import Modal from '@/components/misc/Modal.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';
import TieredMenu from 'primevue/tieredmenu';
import SplitButton from 'primevue/splitbutton';
import FaxConsultDialog from '@/components/referral/FaxConsultDialog.vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import moment from 'moment';
import MSI from '@/views/user/onboarding/onboardingForm/MSI.vue';
import CapacitorCallPlugin from '@/plugins/CapacitorCallPlugin';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: { Button, Loading, Dialog, Modal, Dropdown, InputText, AutoComplete, InputSwitch, DatePicker, TieredMenu, SplitButton, FaxConsultDialog, MSI },
  props: {
    selectedNoteId: {
      type: String,
      required: false,
      default: null,
    },
    removePadding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      hasScrolled: null,
      isSaving: false,
      displayAllFields: false,
      encounterNote: {
        call_date: new Date(),
        start_datetime: new Date(),
        end_datetime: new Date(),
        is_signed_off: false,
        submit_for_billing: false,
        status: 'Draft',
      },
      note_type: null,
      note_type_options: [
        { id: 1, name: 'standard_encounter_note', display_name: 'Standard Note' },
        { id: 2, name: 'phone_consult_encounter_note', display_name: 'Phone Consult Note' },
        { id: 3, name: 'soap_note_encounter_note', display_name: 'Soap Note' },
      ],
      billing_code_options: [
        { option: "I don't want to bill", value: null },
        { option: '0309', value: '0309' },
      ],
      diagnosticCodeUnknown: false,
      filteredDiagnosticCodesOntario: null,
      disableConsultNoteInput: false,
      submittedToBilling: false,
      showFaxConsultDialog: false,
      errors: {},
      openAddendum: false,
      editingMode: false,
      autoSaveTimer: null,
      baseMenuItemOptions: [
        {
          label: 'Make Call',
          icon: 'pi pi-phone',
          command: () => {
            this.makeCall();
          },
        },
        {
          label: 'Delete Note',
          icon: 'pi pi-trash',
          command: () => {
            this.deleteEncounterNoteOnClick();
          },
          disabled: () => this.encounterNote?.status != 'Draft',
        },
        {
          label: 'Download PDF',
          icon: 'pi pi-download',
          command: () => {
            this.downloadPdfOfCombinedForm(this.encounterNote.id)
              .then(() => {})
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        },
        {
          label: `Fax Note`,
          icon: 'pi pi-file-pdf',
          command: () => {
            this.fax_document = 'encounter_note';
            this.faxDocumentId = this.encounterNote.id;
            this.showFaxConsultDialog = true;
          },
        },
        {
          label: `New Note`,
          icon: 'pi pi-plus',
          command: () => {
            this.$router.push('/add-note');
          },
        },
      ],
      finishedMenuItemOptions: [
        {
          label: 'Download PDF',
          icon: 'pi pi-download',
          command: () => {
            this.downloadPdfOfCombinedForm(this.encounterNote.id)
              .then(() => {})
              .catch(() => {
                this.showGenericErrorToast();
              });
          },
        },
        {
          label: `Fax Note`,
          icon: 'pi pi-file-pdf',
          command: () => {
            this.fax_document = 'encounter_note';
            this.faxDocumentId = this.encounterNote.id;
            this.showFaxConsultDialog = true;
          },
        },
        {
          label: `Add Addendum`,
          icon: 'pi pi-pencil',
          command: () => {
            this.$refs.addendumsComponent.showMakeAddendumInputComponent();
          },
        },
        {
          label: `New Note`,
          icon: 'pi pi-plus',
          command: () => {
            this.$router.push('/add-note');
          },
        },
      ],
      menuItems: this.baseMenuItemOptions,
      isLoadingSave: false,
      isLoadingSubmit: false,
      openBillingArrangementDialog: false,
      hasSubmittedBillingArrangement: false,
      openBillingConditionsDialog: false,
      showSubmitForBillingDialog: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'allProvinces', 'actingUser', 'hasProvinceOfPractice', 'diagnosticCodes', 'diagnosticCodesOntario', 'referral', 'referralAddendums', 'selectedEncounterNoteId', 'isSpecialist', 'billingArrangement', 'getUserActionByName', 'activeTour']),
    note_type_icon() {
      const selectedNoteType = this.note_type_options.find((option) => option.id === this.note_type);

      if (!selectedNoteType) return 'file';

      switch (selectedNoteType.name) {
        case 'phone_consult_encounter_note':
          return 'file-medical';
        case 'soap_note_encounter_note':
          return 'file-lines';
        case 'standard_encounter_note':
        default:
          return 'file';
      }
    },
    // isReadyForBilling() {
    //   return this.isSignedOff && this.encounterNote.referral_question && this.encounterNote.patient_first_name && this.encounterNote.patient_last_name && this.encounterNote.patient_dob && this.encounterNote.health_card_province && this.encounterNote.health_card_number && this.encounterNote.health_card_version_number && this.encounterNote.group_number && this.encounterNote.patient_sex && (this.encounterNote.diagnostic_code || this.encounterNote.diagnosis) && this.encounterNote.consult_with_first_name && this.encounterNote.consult_with_last_name;
    // },
    isComplete() {
      return this.encounterNote?.status === 'Complete';
    },
    isSignedOff() {
      return this.encounterNote?.status === 'Signed Off';
    },
    encounterNoteAddendums() {
      return this.referralAddendums.filter((addendum) => addendum.type === 'encounter_note');
    },
    showMakeAddendumButton() {
      return ['Signed Off', 'Complete'].includes(this.encounterNote.status) && !this.openAddendum;
    },
    canAutoSave() {
      return this.encounterNote.patient_first_name || this.encounterNote.patient_last_name || this.encounterNote.referral_question;
    },
  },
  methods: {
    ...mapActions(['getAllProvinces', 'getReferralById', 'getDiagnosticCodes', 'getDiagnosticCodesOntario', 'createOrUpdateEncounterNote', 'downloadPdfOfCombinedForm', 'getReferralAddendumsByReferralId', 'createReferralAddendum', 'getBillingArrangement', 'deleteEncounterNote']),
    ...mapMutations(['setSelectedEncounterNoteId', 'setReferralAddendums']),

    async startCall() {
      if (this.isNativePlatform()) {
        this.makeCall('pstn');
      } else {
        this.$toast.add({
          severity: 'info',
          summary: 'Calling currently unavailable',
          detail: 'Please use the iOS app to make a call.',
          life: 3000,
        });
      }
    },

    async makeCall() {
      this.$toast.add({
        severity: 'info',
        summary: 'Please Wait',
        detail: 'Currently making call. Please try again in a moment.',
        life: 3000,
      });
    },
    backToNotesOnClick() {
      this.setSelectedEncounterNoteId(null);
      this.setReferralAddendums([]);
      this.$router.push('/notes');
    },
    toggleEditingMode() {
      this.editingMode = !this.editingMode;
    },
    handleSubmitForBillingOnClick() {
      this.openBillingConditionsDialog = true;
    },
    openFaxDialogOnClick() {
      this.fax_document = 'encounter_note';
      this.faxDocumentId = this.encounterNote.id;
      this.showFaxConsultDialog = true;
    },
    deleteEncounterNoteOnClick() {
      if (this.encounterNote.status !== 'Draft') {
        return;
      }
      const encounterNote = JSON.parse(JSON.stringify(this.encounterNote));
      encounterNote.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      encounterNote.submit_for_billing = false;
      encounterNote.is_signed_off = false;

      this.deleteEncounterNote(encounterNote)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Note Deleted',
            detail: 'You successfully deleted this note.',
            life: 3000,
          });
          this.backToNotesOnClick();
        })
        .catch(() => {
          this.showGenericErrorToast();
        });
    },
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      const heightToChange = 45;
      if (this.hasScrolled === null && scrollPosition > heightToChange) {
        this.hasScrolled = scrollPosition > heightToChange;
      } else if (this.hasScrolled !== null) {
        this.hasScrolled = scrollPosition > heightToChange;
      }
    },
    searchDiagnosticCode(event) {
      this.getDiagnosticCodes(event.query)
        .then(() => {})
        .catch(() => {});
    },
    searchDiagnosticCodeOntario(event) {
      this.getDiagnosticCodesOntario(event.query)
        .then(() => {
          setTimeout(() => {
            if (!event.query.trim().length) {
              this.filteredDiagnosticCodesOntario = [...this.diagnosticCodesOntario];
            } else {
              this.filteredDiagnosticCodesOntario = this.diagnosticCodesOntario.filter((diagnostic) => {
                return diagnostic.code_text.toLowerCase().includes(event.query.toLowerCase());
              });
            }
          }, 250);
        })
        .catch(() => {});
    },
    toggleDiagnosticCodeDiagnosis() {
      this.encounterNote.diagnostic_code = null;
      this.encounterNote.diagnosis = null;
    },
    signOffOnClick() {
      if (this.isSaving) {
        this.$toast.add({
          severity: 'info',
          summary: 'Please Wait',
          detail: 'Currently saving changes. Please try again in a moment.',
          life: 3000,
        });
        return;
      }

      if (this.autoSaveTimer) {
        this.autoSaveEncounterNote()
          .then(() => {
            if (!this.selectedNoteId) {
              this.setSelectedEncounterNoteId(this.referral.id);
            }
            this.encounterNote.submit_for_billing = false;
            this.encounterNote.is_signed_off = true;
            this.editingMode = false;
            this.createOrUpdateEncounterNoteSubmit();
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to save the note. Please try again.',
              life: 3000,
            });
          });
      } else {
        if (!this.selectedNoteId) {
          this.setSelectedEncounterNoteId(this.referral.id);
        }
        this.encounterNote.submit_for_billing = false;
        this.encounterNote.is_signed_off = true;
        this.editingMode = false;
        this.createOrUpdateEncounterNoteSubmit();
      }
    },
    submitForBillingOnClick() {
      this.displayAllFields = true;
      this.validateIsReadyForBilling();

      if (Object.keys(this.errors).length !== 0) {
        return;
      }

      if (!this.hasSubmittedBillingArrangement) {
        console.log('user hasnt submitted billing arrangement');
        this.openBillingArrangementDialog = true;
        this.encounterNote.submit_for_billing = false;
        this.createOrUpdateEncounterNoteSubmit();
        return;
      }

      if (!this.showSubmitForBillingDialog && this.getUserActionByName(this.loggedInUser, 'submit_note_for_billing')?.status === 'Active') {
        this.showSubmitForBillingDialog = true;
        return;
      } else {
        this.encounterNote.submit_for_billing = true;
        console.log('user is submitting note for billing');
        this.createOrUpdateEncounterNoteSubmit();
      }
    },
    createOrUpdateEncounterNoteSubmit() {
      if (this.isSaving) {
        return Promise.resolve();
      }

      this.isSaving = true;
      this.encounterNote.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (this.encounterNote.patient_dob) {
        this.encounterNote.patient_dob = moment(this.encounterNote.patient_dob).format('YYYY-MM-DD');
      }

      if (this.encounterNote.call_date == null || isNaN(this.encounterNote.call_date)) {
        this.encounterNote.call_date = new Date();
      }
      if (this.encounterNote.start_datetime == null || isNaN(this.encounterNote.start_datetime)) {
        const dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setSeconds(0);
        dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 10) * 10);
        this.encounterNote.start_datetime = dateTime;
      }

      if (this.encounterNote.end_datetime == null || isNaN(this.encounterNote.end_datetime)) {
        const dateTime = new Date();
        dateTime.setMilliseconds(0);
        dateTime.setSeconds(0);
        dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 10) * 10);
        this.encounterNote.end_datetime = dateTime;
      }

      // this.isLoadingSave = !this.encounterNote.is_signed_off || this.encounterNote.status !== 'Draft';
      // this.isLoadingSubmit = this.encounterNote.is_signed_off;
      this.resetAutoSaveTimer();

      const encounterNote = JSON.parse(JSON.stringify(this.encounterNote));
      encounterNote.submit_for_billing = this.encounterNote.submit_for_billing;

      let startDate = moment(encounterNote.start_datetime);
      let endDate = moment(encounterNote.end_datetime);
      let callDate = moment(encounterNote.call_date);

      let diffInMin = startDate.diff(endDate, 'minutes');
      startDate.year(callDate.year()).month(callDate.month()).date(callDate.date());
      endDate = moment(startDate);

      endDate.add(Math.abs(diffInMin), 'minutes');

      encounterNote.start_datetime = startDate.format('YYYY-MM-DD H:mm:ss');
      encounterNote.end_datetime = endDate.format('YYYY-MM-DD H:mm:ss');
      encounterNote.user_id = this.loggedInUser.id;

      return this.createOrUpdateEncounterNote(encounterNote)
        .then((response) => {
          this.showSubmitForBillingDialog = false;
          this.resetPatientDOB();
          this.$toast.removeAllGroups();
          this.isSaving = false;

          this.encounterNote.id = this.referral.id;
          this.encounterNote.status = this.referral.status;
          this.encounterNote.updated_at = this.referral.updated_at;
          this.encounterNote.interaction_type_category_id = this.encounterNote.interaction_type_category_id;

          this.errors = {};
          this.setDisplayIsFullNote();

          if (!this.selectedNoteId) {
            this.setSelectedEncounterNoteId(this.referral.id);
          }
          this.emitEncounterNoteSaved(this.encounterNote.id);

          if (['Signed Off', 'Complete'].includes(this.encounterNote.status)) {
            this.menuItems = this.finishedMenuItemOptions;
          } else {
            this.menuItems = this.baseMenuItemOptions;
          }
          this.resetAutoSaveTimer();
          setTimeout(() => {
            this.resetAutoSaveTimer();
          }, 2500);

          return response;
        })
        .catch((error) => {
          // this.isLoadingSave = false;
          // this.isLoadingSubmit = false;
          this.isSaving = false;

          if (this.encounterNote.status != 'Complete') {
            this.resetAutoSaveTimer();
          }

          throw error;
        });
    },
    validateIsReadyForBilling() {
      this.errors = {};
      if (this.encounterNote.patient_first_name == null || this.encounterNote.patient_first_name == '') {
        this.errors.patient_first_name = 'Patient first name is required.';
      }
      if (this.encounterNote.patient_last_name == null || this.encounterNote.patient_last_name == '') {
        this.errors.patient_last_name = 'Patient last name is required.';
      }
      if (this.encounterNote.patient_dob == null) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.encounterNote.health_card_number == null || this.encounterNote.health_card_number == '') {
        this.errors.health_card_number = 'Health Card/ID Number is required.';
      }
      if (!this.encounterNote.health_card_province) {
        this.errors.health_card_province = 'Health Card/Policy Type is required.';
      }
      if (this.encounterNote.patient_dob == null || this.encounterNote.patient_dob == '' || isNaN(new Date(this.encounterNote.patient_dob).getTime())) {
        this.errors.patient_dob = 'Patient date of birth is required.';
      }
      if (this.encounterNote.patient_sex !== 'Male' && this.encounterNote.patient_sex !== 'Female') {
        this.errors.patient_sex = 'Patient sex is required.';
      }
      if (this.encounterNote.health_card_province === 'Blue Cross' && (!this.encounterNote.group_number || this.encounterNote.group_number == '')) {
        this.errors.group_number = 'Group Number is required.';
      }
      if (!this.diagnosticCodeUnknown && !this.encounterNote.diagnostic_code?.code && !this.encounterNote.diagnostic_code?.text) {
        this.errors.diagnostic_code = 'A valid diagnostic code is required. Please select a code by typing in the Diagnosis Code field. If you can not find the correct code, please press the Diagnostic Code Undetermined/Unknown button to type the code.';
      } else if (this.diagnosticCodeUnknown && !this.encounterNote.diagnosis) {
        this.errors.diagnosis = 'Diagnosis is required.';
      }
      if (this.encounterNote.consult_with_first_name == null || this.encounterNote.consult_with_first_name == '') {
        this.errors.consult_with_first_name = 'Encounter with first name is required.';
      }
      if (this.encounterNote.consult_with_last_name == null || this.encounterNote.consult_with_last_name == '') {
        this.errors.consult_with_last_name = 'Encounter with last name is required.';
      }
      if (this.encounterNote.call_date == null || isNaN(this.encounterNote.call_date)) {
        this.errors.call_date = 'Call Date is required.';
      }
      if (this.encounterNote.start_datetime == null || isNaN(this.encounterNote.start_datetime)) {
        this.errors.start_datetime = 'Start Time is required.';
      }
      if (this.encounterNote.end_datetime == null || isNaN(this.encounterNote.end_datetime)) {
        this.errors.end_datetime = 'End Time is required.';
      }
    },
    resetPatientDOB() {
      this.patientDoB = this.referral.referral_form.patient_dob != null ? new Date(Date.parse(this.referral.referral_form.patient_dob + 'T00:00:00')).toUTCString() : null;
      this.encounterNote.patient_dob = this.patientDoB;
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    processTabs(event) {
      let textarea = document.getElementById(event.target.id);
      let start = textarea.selectionStart;
      textarea.value = textarea.value.substring(0, textarea.selectionStart) + '\t' + textarea.value.substring(textarea.selectionStart, textarea.value.length);
      event.target.selectionStart = event.target.selectionEnd = start + 1;
    },
    autoSaveEncounterNote() {
      if (!this.canAutoSave || this.isSaving) {
        return Promise.resolve();
      }

      this.encounterNote.is_signed_off = this.isComplete || this.isSignedOff;
      this.encounterNote.submit_for_billing = false;
      return this.createOrUpdateEncounterNoteSubmit();
    },
    setAutoSaveEncounterNote() {
      this.resetAutoSaveTimer();
      this.autoSaveTimer = setTimeout(() => {
        if (this.editingMode == true) {
          this.autoSaveEncounterNote();
        }
      }, 6000);
    },
    resetAutoSaveTimer() {
      if (this.autoSaveTimer) {
        clearTimeout(this.autoSaveTimer);
        this.autoSaveTimer = null;
      }
    },
    setDisplayIsFullNote() {
      if (this.encounterNote.patient_dob || this.encounterNote.health_card_province || this.encounterNote.health_card_number || this.encounterNote.health_card_version_number || this.encounterNote.group_number || this.encounterNote.patient_sex === 'Male' || this.encounterNote.patient_sex === 'Female' || this.encounterNote.diagnostic_code || this.encounterNote.diagnosis || this.encounterNote.consult_with_first_name || this.encounterNote.consult_with_last_name) {
        this.displayAllFields = true;
      }
      if (['Signed Off', 'Complete'].includes(this.encounterNote.status)) {
        this.menuItems = this.finishedMenuItemOptions;
      } else {
        this.menuItems = this.baseMenuItemOptions;
      }
    },
    closeBillingArrangementDialog(event) {
      this.openBillingArrangementDialog = event.show;
      this.hasSubmittedBillingArrangement = event.data != null;
      if (this.hasSubmittedBillingArrangement) {
        this.$toast.add({
          severity: 'success',
          summary: 'Billing Arrangement Submitted',
          detail: 'You are ready to submit the note to billing',
          life: 3000,
        });
      }
    },
    hasShadowBilled(referral) {
      return referral?.billings?.find((billing) => billing.user_id === this.actingUser.id)?.is_shadow_billing_export;
    },
    onDateSelected(val) {
      this.encounterNote.patient_dob = val;
    },
    appendToReferral(summary) {
      if (this.encounterNote.status === 'Draft') {
        this.encounterNote.referral_question = this.encounterNote.referral_question ? this.encounterNote.referral_question + '\n' + summary : summary;
        return;
      }

      this.$refs.addendumsComponent.pasteNewAddendum(summary);
    },
    emitEncounterNoteSaved(id) {
      this.$emit('encounter-note-saved', id);
    },
    initializeEncounterTimes() {
      const now = new Date();
      const baseDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), Math.floor(now.getMinutes() / 10) * 10, 0, 0);

      this.encounterNote.call_date = now;
      this.encounterNote.start_datetime = baseDate;
      this.encounterNote.end_datetime = new Date(baseDate.getTime() + 10 * 60000); // Add 10 minutes
    },
    onBillingCodeChange(event) {
      if (event.value === '0309') {
        this.note_type = 2;
        this.encounterNote.interaction_type_category_id = 2;
        this.displayAllFields = true;
      } else {
        this.note_type = 1;
        this.encounterNote.interaction_type_category_id = 1;
      }
    },
    onNoteTypeChange(event) {
      this.encounterNote.interaction_type_category_id = event.value;
      if (event.value === 2 || event.value === 3) {
        this.displayAllFields = true;
      } else {
        this.displayAllFields = false;
      }
    },
  },
  created() {},
  mounted() {
    if (this.isNativePlatform()) {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.initializeEncounterTimes();
    this.editingMode = true;
    let promises = [];
    promises.push(this.getAllProvinces({ country: this.loggedInUser.practice_province.country }));
    promises.push(this.getBillingArrangement({ userId: this.loggedInUser.id, practiceProvinceId: this.loggedInUser.practice_province.id }));
    this.isLoading = true;
    if (this.selectedNoteId) {
      promises.push(this.getReferralById({ id: this.selectedNoteId, setState: true }));
      promises.push(this.getReferralAddendumsByReferralId({ referral_id: this.selectedNoteId }));
      this.setSelectedEncounterNoteId(this.selectedNoteId);
    } else {
      this.setSelectedEncounterNoteId(null);
      this.setReferralAddendums([]);
    }
    Promise.all(promises)
      .then(() => {
        if (this.selectedNoteId) {
          this.encounterNote = { ...this.referral, ...this.referral.referral_form, ...this.referral.referral_response_form };
          this.encounterNote.id = this.selectedNoteId;
          this.patientDoB = this.referral.referral_form.patient_dob != null ? new Date(Date.parse(this.referral.referral_form.patient_dob + 'T00:00:00')).toUTCString() : null;
          this.encounterNote.patient_dob = this.patientDoB;

          this.encounterNote.call_date = moment(this.referral.start_datetime).toDate();
          this.encounterNote.start_datetime = moment(this.referral.start_datetime).toDate();
          this.encounterNote.end_datetime = moment(this.referral.end_datetime).toDate();
          this.note_type = this.referral.interaction_type_category?.id || 1;

          if (this.encounterNote.referee.role === 'gp') {
            this.encounterNote.consult_with_first_name = this.encounterNote.referral_person_first_name;
            this.encounterNote.consult_with_last_name = this.encounterNote.referral_person_last_name;
          } else {
            this.encounterNote.consult_with_first_name = this.encounterNote.referee_first_name;
            this.encounterNote.consult_with_last_name = this.encounterNote.referee_last_name;
          }

          if (this.referral.diagnostic_code === null && this.referral.referral_response_form.diagnosis) {
            this.diagnosticCodeUnknown = true;
          }

          if (['Signed Off', 'Complete'].includes(this.encounterNote.status)) {
            this.editingMode = false;
            this.menuItems = this.finishedMenuItemOptions;
          } else {
            this.editingMode = true;
            this.menuItems = this.baseMenuItemOptions;
          }

          this.setDisplayIsFullNote();
        }

        this.hasSubmittedBillingArrangement = this.billingArrangement != null && this.billingArrangement.status === 'Submitted';
        this.encounterNote.user_id = this.actingUser.id;
        this.healthCardProvinceOptions = this.allProvinces;
        this.healthCardProvinceOptions.push({ name: 'Canadian Armed Forces (CAF)' });
        this.healthCardProvinceOptions.push({ name: 'Royal Canadian Mounted Police (RCMP)' });
        this.healthCardProvinceOptions.push({ name: 'Immigration, Refugees and Citizenship Canada (IRCC)' });
        this.healthCardProvinceOptions.push({ name: 'Veterans Affairs Canada (VAC)' });
        if (this.hasProvinceOfPractice(this.actingUser, 'ON')) {
          this.healthCardProvinceOptions.push({ name: 'University Health Insurance Health Plan' });
        }
        this.healthCardProvinceOptions.push({ name: 'Blue Cross' });
        this.healthCardProvinceOptions.push({ name: 'Interim Federal Health Program' });
        this.healthCardProvinceOptions.push({ name: 'Other' });

        this.isLoading = false;
        this.resetAutoSaveTimer();

        this.$watch('encounterNote', {
          handler(val) {
            this.encounterNote.is_signed_off = this.isComplete || this.isSignedOff;
            this.encounterNote.submit_for_billing = this.encounterNote.status === 'Complete';
            this.setAutoSaveEncounterNote();
          },
          deep: true,
        });
      })
      .catch((error) => {
        this.resetAutoSaveTimer();
        this.isLoading = false;

        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          this.$router.push('/add-note');
        }
      });
  },
  beforeUnmount() {
    if (this.canAutoSave && this.editingMode === true && this.encounterNote.status !== 'Complete') {
      this.encounterNote.is_signed_off = this.isComplete || this.isSignedOff;
      this.createOrUpdateEncounterNoteSubmit();
    }

    this.resetAutoSaveTimer();
    if (this.isNativePlatform()) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name && to.name.includes('AddEncounterNote')) {
        window.location.reload();
      }
    },
    'encounterNote.start_datetime'() {
      let startTime = moment(this.encounterNote.start_datetime);
      let endTime = moment(this.encounterNote.end_datetime);
      if (endTime.isSameOrBefore(startTime) || endTime.diff(startTime, 'hours') >= 2) {
        endTime = startTime.add(10, 'minutes');
        this.encounterNote.end_datetime = new Date(moment(endTime));
      }
    },
    'encounterNote.end_datetime'() {
      let startTime = moment(this.encounterNote.start_datetime);
      let endTime = moment(this.encounterNote.end_datetime);
      if (endTime.isSameOrBefore(startTime) || endTime.diff(startTime, 'hours') >= 2) {
        startTime = endTime.subtract(10, 'minutes');
        this.encounterNote.start_datetime = new Date(moment(startTime));
      }
    },
    note_type(newValue) {
      if (newValue?.id === 2 || newValue?.id === 3) {
        this.displayAllFields = true;
      } else if (newValue?.id === 1) {
        this.displayAllFields = false;
      }
    },
  },
};
</script>

<style>
.vc-time-picker .vc-time-header {
  display: none !important;
}
</style>
